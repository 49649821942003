import { Box, Typography } from "@mui/material";

const InfoItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box>
      <Typography fontWeight={600}>{label}</Typography>
      <Typography color={"#ACA8A8"} fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};

export default InfoItem;
