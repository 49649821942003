import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";

export const PublicRoutes: React.FC<{
  firstRouteToAccess: string | undefined;
}> = ({ firstRouteToAccess }) => {
  const [auth] = useRecoilState(authState);

  return !auth.user ? <Outlet /> : <Navigate to={`/${firstRouteToAccess}`} />;
};