import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import { httpsCallable } from "firebase/functions";
import { functions } from "src/firebase";

const Actions = ({ rowId }: { rowId: string }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //callable functions
  const archiveLocation = httpsCallable(functions, "unarchiveLocation");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleActivate = () => {
    handleClose();
    setIsLoading(true);
    archiveLocation({ locationId: rowId })
      .then(() => {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "Activated location successfully",
          },
        ]);
        handleClosePopup();
        setIsLoading(false);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleNavigateToEditPage = () => {
    // navigate(`/employees/${rowId}/edit`);
  };

  return (
    <Stack>
      <LoadingButton
        variant="contained"
        color="inherit"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        size="small"
        sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600, }}
      >
        Actions
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleNavigateToEditPage}
          sx={{ width: "105px", color: "primary.main" }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenPopup}>Activate</MenuItem>
      </Menu>

      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="This action will remove this location from archived list and add it to
        active list"
        submitBtnLabel="Activate"
        onSave={handleActivate}
        isLoading={isLoading}
      />
    </Stack>
  );
};
export default Actions;
