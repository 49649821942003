import { Grid, Typography } from "@mui/material";

interface IProps {
  serviceType: string;
  parkingSpaces: string;
  payment: string;
  pricing: string;
}

const ServicesInfo = (props: IProps) => {
  const { serviceType, parkingSpaces, payment, pricing } = props;
  return (
    <Grid container spacing={{xs: 2, sm: 0}}>
      <Grid item xs={6} sm={3}>
        <Typography
          fontSize={"16px"}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#FFFFFF"}
          mb={"5px"}
        >
          Service Type
        </Typography>
        <Typography
          fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#ACA8A8"}
        >
          {serviceType}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Typography
          fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#FFFFFF"}
          mb={"5px"}
        >
          Total parking spaces
        </Typography>
        <Typography
          fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#ACA8A8"}
        >
          {parkingSpaces}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography
         fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#FFFFFF"}
          mb={"5px"}
        >
          Payment
        </Typography>
        <Typography
          fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#ACA8A8"}
        >
          {payment}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Typography
          fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#FFFFFF"}
          mb={"5px"}
        >
          Pricing
        </Typography>
        <Typography
         fontSize={{xs: "14px", sm: "16px"}}
          fontWeight={600}
          lineHeight={"16px"}
          color={"#ACA8A8"}
        >
          {pricing}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ServicesInfo;
