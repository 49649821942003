import { Box, Typography } from "@mui/material";

const StatusChip = ({
  label,
  color = "warning",
}: {
  label: string;
  color?: "warning" | "success" | "error" | "gray" | "blue";
}) => {
  return (
    <Box
      p={"7px"}
      borderRadius={"4px"}
      bgcolor={
        color === "success"
          ? "#50CD89"
          : color === "error"
          ? "#F1416C"
          : color === "gray"
          ? "#616161"
          : color === "blue"
          ? "#508ACD"
          : "#CD9B50" //warning
      }
      width={"fit-content"}
    >
      <Typography fontWeight={600} fontSize={"12px"}>
        {label}
      </Typography>
    </Box>
  );
};

export default StatusChip;
