import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IIncomeReports } from "src/types/business-intelligence";

const colors = ["#A155B9", "#CC9C33"];

const PaymentsChart = ({ data }: { data: IIncomeReports | undefined }) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const xAxisLabels: any = [];
  const paidArr: any = [];
  const refundedArr: any = [];
  const freeArr: any = [];
  const cancelledArr: any = [];

  data?.data.forEach((item) => {
    xAxisLabels.push(item.label);
    paidArr.push(item.completedAmount);
    refundedArr.push(item.refundedAmount);
    freeArr.push(0);
    cancelledArr.push(0);
  });

  const [series] = useState([
    {
      name: "Paid",
      data: paidArr,
    },

    {
      name: "Free",
      data: freeArr,
    },
    {
      name: "Cancelled",
      data: cancelledArr,
    },
  ]);

  const options: ApexCharts.ApexOptions = {
    theme: {
      mode: "dark",
    },
    noData: {
      text: "No data available ",
      style: {
        fontSize: "15px",
      },
    },
    colors: colors,
    chart: {
      redrawOnParentResize: true,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide the download icons
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Adjust the size of the marker
      shape: "circle",
      strokeColors: colors,
    },
    stroke: {
      width: 2.5,
      curve: "straight",
    },
    grid: {
      show: true,
      borderColor: "#000",
    },
    xaxis: {
      categories: xAxisLabels,
    },
    legend: {
      show: true,
      fontSize: "13.3px",
      markers: {
        width: 23.5,
        height: 9,
        strokeWidth: 0,
        radius: 0,
      },
      itemMargin: {
        horizontal: matchesMediumUp ? 15 : 5,
        vertical: matchesMediumUp ? 5 : 5,
      },
    },
  };

  return (
    <Box px={{ xs: 1, lg: 4 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={300}
        width={"100%"}
      />
    </Box>
  );
};
export default PaymentsChart;
