import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { IPaymentMethodReports } from "src/types/business-intelligence";

const colors = ["#CC9C33","#A155B9","#16AA75" , "#0075FF"];

const PaymentMethodChart = ({
  data,
}: {
  data: IPaymentMethodReports | undefined;
}) => {
  const series: number[] = [];
  if (data) {
    if(data.Card > 0 || data.Cash > 0 || data.Online > 0){
      series.push(data?.Cash);
      series.push(data?.Card);
      series.push(data?.Online);
    }
  }

  const options: ApexCharts.ApexOptions = {
    chart: {
      redrawOnParentResize: true,
      width: 380,
      type: "pie",
      foreColor: "#f0f0f0",
    },
    noData: {
      text: "No data available ",
      style: {
        fontSize: "15px",
      },
    },
    colors: colors,
    labels: ["Cash", "Card", "Online", "Free"],
    stroke: {
      show: false,
    },
  };


  return (
    <Box px={{ xs: 1, lg: 4 }} pb={1.25}>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={"100%"}
          height={300}
        />
      </div>
    </Box>
  );
};
export default PaymentMethodChart;
