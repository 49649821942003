import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableActions from "./table-actions";
import { useEffect, useMemo, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "src/firebase";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate } from "react-router-dom";
import StatusChip from "src/shared/components/status-chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const tableHeadText = [
  "Name in English",
  "Name in Arabic",
  "Client name",
  "Valet",
  "Self parking",
  "Actions",
];

const ActiveLocationsTable = ({
  rowsList,
  isLoading,
}: {
  rowsList: any;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState<string>("");
  const [clients, setClients] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<any>("all");
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [selectedCities, setSelectedCities] = useState<any[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (searchedVal: string) => {
    setPage(0);
    setSearchBy(searchedVal);
  };

  const clearSearch = () => {
    if (searchBy !== "") {
      handleSearch("");
    }
  };

  //Filters
  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };

  const handleChangeClients = (value: any[]) => {
    setSelectedClients(value);
  };

  const handleSearchAndFilters = () => {
    let searchAndFiltersRows: any = [...rowsList];

    //Search by Arabic or English name
    if (!!searchBy.trim()) {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return (
          row.nameEn?.toLowerCase().includes(searchBy.toLowerCase()) ||
          row.clientName?.toLowerCase().includes(searchBy.toLowerCase())
        );
      });
      searchAndFiltersRows = filteredRows;
    }

    //Filter by service
    if (selectedService === "valet") {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return row.enableValet;
      });
      searchAndFiltersRows = filteredRows;
    } else if (selectedService === "selfParking") {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return row.enableSelfParking;
      });
      searchAndFiltersRows = filteredRows;
    }

    //Filter by clients
    if (selectedClients.length > 0) {
      const selectedClientsIds = selectedClients.map(
        (client: any) => client.id
      );
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return selectedClientsIds.includes(row.clientId);
      });
      searchAndFiltersRows = filteredRows;
    }

    return searchAndFiltersRows;
  };

  const searchAndFiltersLocations = handleSearchAndFilters();

  const paginatedVisibleRows = useMemo(
    () =>
      searchAndFiltersLocations.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, rowsList, searchBy, selectedService, selectedClients]
  );

  const emptyDataArr = searchAndFiltersLocations.length === 0 && !isLoading;

  const handleNavigate = () => {
    navigate("/locations/add");
  };

  useEffect(() => {
    let unsubscribeClients: () => void;
    let unsubscribeCities: () => void;

    unsubscribeClients = onSnapshot(
      query(collection(db, "clients")),
      (QuerySnapshot) => {
        const fetchedClients: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedClients.push({ ...doc.data(), id: doc.id });
        });

        setClients(fetchedClients);
      }
    );

    unsubscribeCities = onSnapshot(
      query(collection(db, "cities")),
      (QuerySnapshot) => {
        const fetchedCities: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedCities.push({ ...doc.data(), id: doc.id });
        });

        setCities(fetchedCities);
      }
    );

    return () => {
      if (unsubscribeClients) {
        unsubscribeClients();
      }
      if (unsubscribeCities) {
        unsubscribeCities();
      }
    };
  }, []);

  return (
    <>
      <Stack direction={"row"} gap={2} mb={4} flexWrap={"wrap"}>
        <TextField
          size="small"
          placeholder="search"
          value={searchBy}
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ opacity: !searchBy ? 0 : 1 }}
              >
                <IconButton size="small" onClick={clearSearch}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl size="small" sx={{ minWidth: "180px" }}>
          <InputLabel id="demo-simple-select-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={role}
            label="Role"
            // onChange={handleChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {cities?.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.nameEn}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: "180px" }}>
          <InputLabel id="demo-simple-select-label">Service</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            label="Service"
            onChange={handleChangeServiceType}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"valet"}>Valet</MenuItem>
            <MenuItem value={"selfParking"}>Self Parking</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          sx={{ minWidth: "250px", maxWidth: { xs: "100%", sm: "350px" } }}
          size="small"
          limitTags={6}
          multiple
          id="tags-outlined"
          options={clients}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option, { selected }) => (
            <li key={props.id} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.name}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="client" placeholder="" />
          )}
          onChange={(event, value) => {
            handleChangeClients(value);
          }}
        />
        <Button variant="contained" sx={{ gap: 0.5 }} onClick={handleNavigate}>
          <AddBoxIcon fontSize="small" sx={{ opacity: 0.7 }} />
          Add location
        </Button>
      </Stack>
      <TableContainer
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#A1A5B7",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={6} />
          ) : (
            <TableBody>
              {paginatedVisibleRows.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{row.nameEn}</TableCell>
                  <TableCell>{row.nameAr}</TableCell>
                  <TableCell>{row.clientName}</TableCell>
                  <TableCell>
                    <StatusChip
                      color={row.enableValet ? "success" : "warning"}
                      label={row.enableValet ? "ACTIVE" : "INACTIVE"}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusChip
                      label={row.enableSelfParking ? "ACTIVE" : "INACTIVE"}
                      color={row.enableSelfParking ? "success" : "warning"}
                    />
                  </TableCell>
                  <TableCell>
                    <TableActions rowId={row.id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {emptyDataArr && <NoData />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchAndFiltersLocations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
export default ActiveLocationsTable;
