import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import TicketsChart from "./chart";
import { ITicketReports } from "src/types/business-intelligence";
import exclamationMarkIcon from "src/assets/svg-icons/exlamation-mark.svg";

const TicketsStatistics = ({ data }: { data: ITicketReports | undefined }) => {
  return (
    <Stack
      borderRadius={"8px"}
      boxShadow={" 0px 2.232px 11.161px 0px rgba(0, 0, 0, 0.10)"}
      pt={{ xs: 2, lg: 3 }}
      pb={{ xs: 1, lg: 1 }}
      bgcolor={"#242424"}
    >
      <Grid container px={{ xs: 2, lg: 4 }} spacing={2}>
        <Grid item xs={6} sm={3}>
          <Typography
            fontSize={{ xs: "12px", lg: "14px" }}
            fontWeight={600}
            color={"#CFCFCF"}
          >
            Total Tickets
          </Typography>
          <Typography
            fontSize={{ xs: "16px", lg: "20px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            {data?.total.totalTickets} Ticket
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Stack direction={"row"} spacing={1.5}>
            <Typography
              fontSize={{ xs: "12px", lg: "14px" }}
              fontWeight={600}
              color={"#CFCFCF"}
            >
              Used Tickets
            </Typography>
            <Tooltip title="Paid and free tickets" placement="right">
              <img src={exclamationMarkIcon} alt="" />
            </Tooltip>
          </Stack>

          <Typography
            fontSize={{ xs: "16px", lg: "20px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            {data?.total.totalUsed} Ticket
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            fontSize={{ xs: "12px", lg: "14px" }}
            fontWeight={600}
            color={"#CFCFCF"}
          >
            Canceled Tickets
          </Typography>
          <Typography
            fontSize={{ xs: "16px", lg: "20px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            {data?.total.totalCancelled} Ticket
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            fontSize={{ xs: "12px", lg: "14px" }}
            fontWeight={600}
            color={"#CFCFCF"}
          >
            Refunded Tickets
          </Typography>
          <Typography
            fontSize={{ xs: "16px", lg: "20px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            {data?.total.totalRefunded} Ticket
          </Typography>
        </Grid>
      </Grid>
      <Box
        width={"100%"}
        bgcolor={"#000000"}
        height={"2.3px"}
        my={{ lg: 3, xs: 2 }}
      />
      <TicketsChart data={data} />
    </Stack>
  );
};

export default TicketsStatistics;
