import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Paper, Stack, TablePagination, Typography } from "@mui/material";
import React from "react";
import { functions } from "src/firebase";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { httpsCallable } from "firebase/functions";
import { downloadFile } from "src/utils";

const tableHeadText = [
  "ID",
  "Date",
  "Location",
  "Service",
  "Entry Tickets",
  "Exit Tickets",
  "Paid Tickets",
  "Free Tickets",
  "Cash Payments",
  "Card Payments",
  "Online Payments",
  "Total Sales",
];

interface IProps {
  selectedLocations: any[];
  dateRange: any;
  selectedService: string;
}

const SalesTable = ({
  selectedLocations,
  dateRange,
  selectedService,
}: IProps) => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  //Callable functions
  const fetchSalesTransactionsList = httpsCallable(
    functions,
    "getSalesTransactionDetails"
  );
  const exportSalesTransactionsList = httpsCallable(
    functions,
    "exportSalesTransactionDetails"
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = () => {
    setIsLoadingExport(true);
    exportSalesTransactionsList({
      ...(selectedLocations.length > 0 && {
        locations: selectedLocations.map((loc) => loc.name),
      }),
      ...(selectedService !== "all" && {
        services: [selectedService],
      }),
      ...(!!dateRange.startDate && {
        startDate: new Date(dateRange.startDate).getTime(),
      }),
      ...(!!dateRange.endDate && {
        endDate: new Date(dateRange.endDate).getTime(),
      }),
    })
      .then((result: any) => {
        console.log({ result });
        setIsLoadingExport(false);
        const utf8CSVData = `\uFEFF${result?.data.data}`;
        downloadFile(utf8CSVData, "text/csv", "transactions-details");
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "Downloaded successfully",
          },
        ]);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoadingExport(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const emptyDataArr = data?.salesData.length === 0 && !isLoading;

  useEffect(() => {
    setIsLoading(true);
    fetchSalesTransactionsList({
      ...(selectedLocations.length > 0 && {
        locations: selectedLocations.map((loc) => loc.name),
      }),
      ...(selectedService !== "all" && {
        services: [selectedService],
      }),
      ...(!!dateRange.startDate && {
        startDate: new Date(dateRange.startDate).getTime(),
      }),
      ...(!!dateRange.endDate && {
        endDate: new Date(dateRange.endDate).getTime(),
      }),
      page: page + 1,
      size: rowsPerPage,
    })
      .then((result: any) => {
        console.log({ result });
        setData(result.data.data as any);
        setIsLoading(false);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
      });
  }, [
    selectedLocations,
    dateRange.startDate,
    dateRange.endDate,
    page,
    rowsPerPage,
  ]);

  console.log("rows", { data });

  return (
    <Paper
      sx={{
        bgcolor: "#242424",
        boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
        padding: { xs: "15px", md: "30px" },
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography fontSize={"20px"} fontWeight={700}>
          Transactions Details
        </Typography>
        {!emptyDataArr ? (
          <LoadingButton
            variant="contained"
            sx={{ gap: 0.5, width: "150px" }}
            onClick={handleExport}
            loading={isLoadingExport}
          >
            Export
          </LoadingButton>
        ) : null}
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#CC9C33",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={rowsPerPage} cellsLength={12} />
          ) : (
            <TableBody>
              {data?.salesData.map((row: any, index: number) => (
                <TableRow
                  key={`${row.totalSales}_${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>
                    {row.service === "Valet" ? "Valet" : "Self Parking"}
                  </TableCell>
                  <TableCell>{row.ticketEntryCount}</TableCell>
                  <TableCell>{row.ticketExitCount}</TableCell>
                  <TableCell>{row.ticketPaidCount}</TableCell>
                  <TableCell>{row.freeTicketCount}</TableCell>
                  <TableCell>{row.totalCashSales}</TableCell>
                  <TableCell>{row.totalCardSales}</TableCell>
                  <TableCell>{row.totalOnlineSales}</TableCell>
                  <TableCell>{row.totalSales}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {emptyDataArr && <NoData />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
};
export default SalesTable;
