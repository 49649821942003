import { Box, useMediaQuery, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ITicketReports } from "src/types/business-intelligence";

const colors = ["#0075FF", "#16AA75", "#A155B9"];

const TicketsChart = ({ data }: { data: ITicketReports | undefined }) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const xAxisLabels: any = [];
  const usedArr: any = [];
  const cancelledArr: any = [];
  const refundedArr: any = [];

  data?.data.forEach((item) => {
    xAxisLabels.push(item.label);
    usedArr.push(item.usedTickets);
    cancelledArr.push(item.cancelledTickets);
    refundedArr.push(item.refundedTickets);
  });

  const series = [
    {
      name: "Used",
      data: usedArr,
    },
    {
      name: "Canceled",
      data: cancelledArr,
    },

    {
      name: "Refunded",
      data: refundedArr,
    },
  ];

  const options: ApexCharts.ApexOptions = {
    theme: {
      mode: "dark",
    },
    noData: {
      text: "No data available",
      style: {
        fontSize: "15px",
      },
    },
    colors: colors,
    chart: {
      redrawOnParentResize: true,
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      background: "transparent",
      toolbar: {
        show: false, // Hide the download icons
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Adjust the size of the marker
      shape: "circle",
      strokeColors: colors
    },
    stroke: {
      width: 2.5,
      curve: "straight",
    },
    grid: {
      show: true,
      borderColor: "#000",
    },
    xaxis: {
      categories: xAxisLabels,
    },

    legend: {
      show: true,
      fontSize: "13.3px",
      // offsetY: matchesSmallUp ? 15 : 0,

      markers: {
        width: 23.5,
        height: 9,
        strokeWidth: 0,
        radius: 0,
      },
      itemMargin: {
        horizontal: matchesMediumUp ? 15 : 5,
        vertical: matchesMediumUp ? 20 : 10,
      },
    },
  };

  return (
    <Box px={{ xs: 1, lg: 4 }}>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
          width={"100%"}
        />
      </div>
    </Box>
  );
};
export default TicketsChart;
