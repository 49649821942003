import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ActiveEmployeesTable from "./table";
import {
  query,
  collection,
  onSnapshot,
  where,
  limit,
} from "firebase/firestore";
import { db } from "src/firebase";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";
import { IEmployee } from "src/types/employees";

const ActiveEmployeesPage = () => {
  const [employees, setEmployees] = useState<IEmployee[]>([]); //
  const [isLoading, setIsLoading] = useState<boolean>(false); //

  const queryMessages = query(
    collection(db, "users"),
    where("isUserActive", "==", true)
    // orderBy("createdAt", "desc"),
    // limit(50)
  );

  useEffect(() => {
    setIsLoading(true);
    let unsubscribe: any;
    // If there was a previous subscription, unsubscribe from it before creating a new one
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = onSnapshot(
      query(collection(db, "users"), where("isUserActive", "==", true)),
      (QuerySnapshot) => {
        const fetchedEmployees: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedEmployees.push({ ...doc.data(), id: doc.id });
        });
        const sortedEmployees = fetchedEmployees.sort(
          (a: any, b: any) => a.createdAt - b.createdAt
        );

        setEmployees(fetchedEmployees);
        setIsLoading(false);
      }
    );

    // Return the cleanup function to unsubscribe when the component unmounts or when the selected room changes
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  console.log({ employees });

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Active Employees
      </Typography>
      <ActiveEmployeesTable rowsList={employees} isLoading={isLoading} />
    </Box>
  );
};

export default ActiveEmployeesPage;
