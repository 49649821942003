import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authState } from "src/store/auth";
import { useRecoilState } from "recoil";
import ArrowsIcon from "src/assets/svg-icons/circualr-arrows.svg";

const TableActions = ({ rowId }: { rowId: string }) => {
  const navigate = useNavigate();
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;

  const handleArchive = () => {
    navigate(`/employees/${rowId}/edit`);
  };

  return permissions?.arsannEmployees.edit ? (
    <Stack direction={"row"}>
      <Button
        onClick={handleArchive}
        color="secondary"
        variant="contained"
        sx={{ gap: "5px" }}
        size="small"
      >
        <img src={ArrowsIcon} alt="" />
        Activate
      </Button>
    </Stack>
  ) : null;
};
export default TableActions;
