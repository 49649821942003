import { Box, Hidden } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import MobileSidebar from "./mobile-drawer";
import { NavItems } from "./nav-items";
import AppBar from "./app-bar";

export const Layout: React.FC = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      maxWidth={"100vw"}
    >
      {/* desktop sidebar */}
      <Hidden mdDown>
        <NavItems />
      </Hidden>

      {/* mobile sidebar */}
      <Hidden mdUp>
        <MobileSidebar />
      </Hidden>

      {/* pages content */}
      <Box flexGrow={1}>
        <AppBar />
        <Outlet />
      </Box>
    </Box>
  );
};
