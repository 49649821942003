import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ActiveEmployeesTable from "./table";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { db } from "src/firebase";
import { IEmployee } from "src/types/employees";

const ArchivedEmployeesPage = () => {
  const [employees, setEmployees] = useState<IEmployee[]>([]); //
  const [isLoading, setIsLoading] = useState<boolean>(false); //

  useEffect(() => {
    setIsLoading(true);
    let unsubscribe: () => void;

    unsubscribe = onSnapshot(
      query(collection(db, "users"), where("isUserActive", "==", false)),
      (QuerySnapshot) => {
        const fetchedEmployees: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedEmployees.push({ ...doc.data(), id: doc.id });
        });

        setEmployees(fetchedEmployees);
        setIsLoading(false);
      }
    );

    // Return the cleanup function to unsubscribe when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Archived Employees
      </Typography>
      <ActiveEmployeesTable rowsList={employees} isLoading={isLoading} />
    </Box>
  );
};

export default ArchivedEmployeesPage;
