import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableActions from "./table-actions";
import { useMemo, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import React from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { IEmployee } from "src/types/employees";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";

const tableHeadText = [
  "National ID",
  "Name",
  "Role",
  "Email",
  "Phone",
  "Actions",
];

const ActiveEmployeesTable = ({
  rowsList,
  isLoading,
}: {
  rowsList: IEmployee[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  // const [rows, setRows] = useState(rowsList);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState<string>("");
  const [role, setRole] = useState("all");
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = () => {
    navigate("/employees/add");
  };

  const handleChangeRole = (e: SelectChangeEvent) => {
    setRole(e.target.value);
  };

  const handleSearch = (searchedVal: string) => {
    setPage(0);
    setSearchBy(searchedVal);
  };

  const clearSearch = () => {
    if (searchBy !== "") {
      handleSearch("");
    }
  };

  const handleSearchAndFilters = () => {
    let searchAndFiltersRows = [...rowsList];
    //search by name, email, national id or Phone
    if (!!searchBy.trim()) {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return (
          row.name?.toLowerCase().includes(searchBy.toLowerCase()) ||
          row.email?.toLowerCase().includes(searchBy.toLowerCase()) ||
          row.nationalId?.toLowerCase().includes(searchBy.toLowerCase()) ||
          row.phone?.toLowerCase().includes(searchBy.toLowerCase())
        );
      });
      searchAndFiltersRows = filteredRows;
    }
    //filter by role
    if (role === "admin") {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return row.roles.includes("Admin");
      });
      searchAndFiltersRows = filteredRows;
    } else if (role === "operator") {
      const filteredRows = searchAndFiltersRows.filter((row: any) => {
        return row.roles.includes("Operator");
      });
      searchAndFiltersRows = filteredRows;
    }
    return searchAndFiltersRows;
  };

  const searchAndFiltersList = handleSearchAndFilters();

  const paginatedVisibleRows = useMemo(
    () =>
      searchAndFiltersList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, rowsList, searchBy, role]
  );

  const emptyDataArr = searchAndFiltersList.length === 0 && !isLoading;

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        gap={2}
        mb={4}
        flexWrap={"wrap"}
      >
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <TextField
            size="small"
            placeholder="Search"
            value={searchBy}
            onChange={(event) => handleSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ opacity: !searchBy ? 0 : 1 }}
                >
                  <IconButton size="small" onClick={clearSearch}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl size="small" sx={{ minWidth: "180px" }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={handleChangeRole}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"operator"}>Operator</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {permissions?.arsannEmployees.create && (
          <Button
            variant="contained"
            sx={{ gap: 0.5 }}
            onClick={handleNavigate}
          >
            <AddBoxIcon fontSize="small" sx={{ opacity: 0.7 }} />
            Add New User
          </Button>
        )}
      </Stack>
      <TableContainer
        sx={{
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#A1A5B7",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={7} cellsLength={6} />
          ) : (
            <TableBody>
              {paginatedVisibleRows?.map((row) => (
                <TableRow
                  key={row.employeeId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.nationalId}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell sx={{ maxWidth: "250px" }}>
                    {row.roles?.map(
                      (role: any, index: number) =>
                        `${role}${index !== row.roles.length - 1 ? " - " : ""}`
                    )}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <TableActions
                      rowId={row.employeeId}
                      setSearchBy={setSearchBy}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {emptyDataArr && <NoData />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchAndFiltersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
export default ActiveEmployeesTable;
