import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";

export const schema = yup.object().shape({
  type: yup
    .string()
    .required("required")
    .oneOf(["valet", "self"] as const),
  name: yup.string().required("Required"),
  parkingSpaces: yup.string().required("Required"),
  payment: yup
    .string()
    .required("required")
    .oneOf(["entry", "exit", "flexible"] as const),
  pricing: yup
    .string()
    .required("required")
    .oneOf(["free", "fixed", "custom"] as const),
  price: yup.string().when("pricing", {
    is: (pricing: string) => ["fixed", "custom"].includes(pricing),
    then: (value) => value.required("Required"),
  }),
  //   TODO as it is not working
  gracePeriod: yup.string().when(["pricing", "payment"], {
    // is: ([pricing, payment] : any) =>
    //   ["fixed", "custom"].includes(pricing) && payment === "exit",
    is: ([pricing = "", payment = ""]: [string, string]) =>
      ["fixed", "custom"].includes(pricing) && payment === "exit",
    then: (schema) => schema.required("Required"),
  }),

  startingHours: yup.string().when("pricing", {
    is: "custom",
    then: (value) => value.required("Required"),
  }),
  priceOfExceedingHours: yup.string().when("pricing", {
    is: "custom",
    then: (value) => value.required("Required"),
  }),
  pricingModal: yup
    .string()
    .required("required")
    .oneOf(["customByHours"] as const),
  exceedingPricingType: yup
    .string()
    .required("required")
    .oneOf(["perHour", "allHours"] as const),
  ticketViolationPrice: yup.string(),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const StyledRadioGroup = styled(RadioGroup)({
  "& .MuiFormControlLabel-label": {
    fontSize: "12px", // Set your desired font size here
  },
});

const initValues = {
  type: "valet",
  pricingModal: "customByHours",
  exceedingPricingType: "perHour",
  pricing: "free",
  //   payment: "",
};

interface IProps {
  open: boolean;
  onClose: any;
  serviceDataToEdit?: any;
  serviceDataToEditIndex?: number;
  setServices: Function;
}

const AddEditServicePopup: React.FC<IProps> = (props) => {
  const {
    open,
    onClose,
    serviceDataToEdit,
    serviceDataToEditIndex,
    setServices,
  } = props;
  const mode = !serviceDataToEdit ? "add" : "edit";

  const methods = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "valet",
      pricingModal: "customByHours",
      exceedingPricingType: "perHour",
      pricing: "free",
      //   payment: "",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = methods;

  const handleClose = () => {
    onClose();
    reset({
      type: "valet",
      pricingModal: "customByHours",
      exceedingPricingType: "perHour",
      pricing: "free",
      //   payment: "",
    });
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      setServices((prev: any) => [...prev, data]);
    } else {
      setServices((prev: any) => {
        // const updatedServices = prev[serviceDataToEditIndex as number] = data;
        prev[serviceDataToEditIndex as number] = data;
        return prev;
      });
    }
    handleClose();
    console.log({ data });
  };

  useEffect(() => {
    if (watch("type") === "valet") {
      setValue("payment", "flexible");
    } else {
      setValue("payment", "exit");
      if (watch("pricing") === "custom" && watch("payment") === "entry") {
        setValue("pricing", "free");
      }
    }
  }, [watch("type")]);

  useEffect(() => {
    if (watch("payment") === "entry" && watch("pricing") === "custom") {
      setValue("pricing", "free");
    }
  }, [watch("payment")]);

  useEffect(() => {
    if (!!serviceDataToEdit) {
      reset({ ...serviceDataToEdit });
    }
  }, [serviceDataToEdit]);

  console.log({ errors });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      component={"div"}
    >
      <DialogTitle sx={{ p: "50px 10px 25px 50px" }}>
        <Typography fontSize={"24px"} fontWeight={500}>
          Service Configuration
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box px={"50px"}>
          {/* Service Type */}
          <Typography fontSize={"20px"} fontWeight={500}>
            Service Type
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.type}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="valet"
                    control={<Radio />}
                    label="Valet Service"
                  />
                  <FormControlLabel
                    value="self"
                    control={<Radio />}
                    label="Self Parking"
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          <Stack direction={"row"} gap={2} mt={2}>
            <Box>
              {/* Service Name */}
              <Typography mb={2} fontSize={"20px"} fontWeight={500}>
                Service Name
              </Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="medium"
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              {/* total parking spaces */}
              <Typography mb={2} fontSize={"20px"} fontWeight={500}>
                Total Parking Spaces
              </Typography>
              <Controller
                name="parkingSpaces"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="medium"
                    {...field}
                    error={!!errors.parkingSpaces}
                    helperText={errors.parkingSpaces?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Stack>

          {/* payment */}
          <Typography fontSize={"20px"} fontWeight={500} mt={"32px"}>
            Payment
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.payment}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="payment"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="entry"
                    control={<Radio />}
                    label="At Entry"
                    disabled={watch("type") === "valet"}
                  />
                  <FormControlLabel
                    value="exit"
                    control={<Radio />}
                    label="At Exit"
                    disabled={watch("type") === "valet"}
                  />
                  <FormControlLabel
                    value="flexible"
                    control={<Radio />}
                    label="Flexible (At Entry or At Exit)"
                    disabled={watch("type") === "self"}
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          {/* pricing */}
          <Typography fontSize={"20px"} fontWeight={500} mt={"32px"}>
            Pricing
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.pricing}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="pricing"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="free"
                    control={<Radio />}
                    label="Free"
                  />
                  <FormControlLabel
                    value="fixed"
                    control={<Radio />}
                    label="Fixed price"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom price"
                    disabled={
                      watch("type") === "self" && watch("payment") === "entry"
                    }
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          {/* fixed pricing */}
          {watch("pricing") === "fixed" && (
            <Stack flexDirection={"row"} gap={2}>
              {watch("payment") === "exit" && (
                <Controller
                  name="gracePeriod"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.gracePeriod}
                      helperText={errors.gracePeriod?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      placeholder="Grace period in mins"
                    />
                  )}
                />
              )}
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="medium"
                    {...field}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                    fullWidth
                    sx={{ maxWidth: "212px", marginTop: "14px" }}
                    placeholder="price"
                  />
                )}
              />
            </Stack>
          )}
          {/* custom pricing */}
          {watch("pricing") === "custom" && (
            <Box>
              {watch("payment") === "exit" && (
                <Controller
                  name="gracePeriod"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.gracePeriod}
                      helperText={errors.gracePeriod?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Grace period in mins"
                    />
                  )}
                />
              )}
              <Stack direction={"row"} gap={2} alignItems={"flex-end"}>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.pricingModal}
                  sx={{ maxWidth: "208px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Pricing Modal
                  </InputLabel>
                  <Controller
                    name="pricingModal"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Pricing Modal"
                      >
                        <MenuItem value={"customByHours"}>
                          Custom by hours
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.pricingModal?.message}
                  </FormHelperText>
                </FormControl>
                <Controller
                  name="startingHours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.startingHours}
                      helperText={errors.startingHours?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Starting hours"
                    />
                  )}
                />
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.price}
                      helperText={errors.price?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Price"
                    />
                  )}
                />
                <Controller
                  name="priceOfExceedingHours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.priceOfExceedingHours}
                      helperText={errors.priceOfExceedingHours?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Price for exceeding hours"
                    />
                  )}
                />
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"100%"}
                >
                  <Typography fontSize={"12px"} fontWeight={500}>
                    Exceeding Price Type
                  </Typography>
                  <FormControl
                    fullWidth
                    size="medium"
                    error={!!errors.exceedingPricingType}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                      mt: "12px",
                    }}
                  >
                    <Controller
                      name="exceedingPricingType"
                      control={control}
                      render={({ field }) => (
                        <StyledRadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="perHour"
                            control={
                              <Radio size="small" sx={{ padding: "0 9px" }} />
                            }
                            label="Per hour"
                          />
                          <FormControlLabel
                            value="allHours"
                            control={
                              <Radio size="small" sx={{ padding: "0 9px" }} />
                            }
                            label="All hour"
                          />
                        </StyledRadioGroup>
                      )}
                    />
                  </FormControl>
                </Box>
              </Stack>
              {watch("payment") === "exit" && (
                <Controller
                  name="ticketViolationPrice"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.ticketViolationPrice}
                      helperText={errors.ticketViolationPrice?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Ticket violation price"
                    />
                  )}
                />
              )}
            </Box>
          )}
        </Box>

        <DialogActions sx={{ padding: "30px 50px 50px 50px" }}>
          <Button sx={{ minWidth: "208px" }} variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditServicePopup;
