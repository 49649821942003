import { Box, Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import DrawerIcon from "src/assets/svg-icons/drawer.svg";
import { NavItems } from "../nav-items";

const MobileSidebar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box p={"14px 16px"}>
      <IconButton sx={{ p: "0" }} onClick={handleOpen}>
        <img width={32} height={32} src={DrawerIcon} alt="" />
      </IconButton>

      <Drawer anchor={"left"} open={open} onClose={handleClose}>
        <NavItems />
      </Drawer>
    </Box>
  );
};

export default MobileSidebar;
