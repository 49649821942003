import * as yup from "yup";

export const schema = yup.object().shape({
  //basic info
  clientId: yup.string().required("Required"),
  nameEn: yup.string().required("Required"),
  nameAr: yup.string().required("Required"),
  address:  yup.string().required("Required"),
  phoneNumber:  yup.string().required("Required"),
  //gates
  gates: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    )
    .required("Choose related payments is required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}
