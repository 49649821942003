import { Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Form from "./components/form";
import BlackHorseImg from "src/assets/black_horse.png";
import LogoIcon from "src/assets/svg-icons/arsann-logo.svg";
import { onSnapshot, query, collection } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "src/firebase";

const LoginPage = () => {
  return (
    <Grid container>
      <Grid
        xs={12}
        md={6}
        px={{ xs: "16px", lg: "180px" }}
        pt={{ xs: "90px", md: "70px" }}
        textAlign={"center"}
      >
        <img src={LogoIcon} alt="" height={"95px"} />
        <Typography
          fontSize={"24px"}
          fontWeight={600}
          lineHeight={"24px"}
          my={"32px"}
        >
          Sign in for your account
        </Typography>
        <Divider sx={{ mb: "35px" }}>
          <Typography fontSize={"12px"} color={"#A1A5B7"}>
            with email
          </Typography>
        </Divider>
        <Form />
      </Grid>
      <Grid
        md={6}
        display={{ xs: "none", md: "flex" }}
        flexDirection={"column"}
        alignItems={"center"}
        borderRadius={2}
        minHeight={"100vh"}
        maxHeight={"100vh"}
      >
        <img height={"100%"} src={BlackHorseImg} alt="" width={"100%"} />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
