import { useState } from "react";
import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";
import { signOut } from "firebase/auth";
import { authentication } from "src/firebase";

const AppBar = () => {
  const [auth] = useRecoilState(authState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user } = auth;

  const handleLogout = () => {
    signOut(authentication)
      .then(() => {
        // Sign-out successful.
        console.log("Signed out successfully");
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("user", {user});

  return (
    <Box
      p={"16px 30px"}
      display={"flex"}
      justifyContent={"flex-end"}
      position={"absolute"}
      top={10}
      right={0}
    >
      <Avatar
        variant="rounded"
        src={user?.providerData?.[0]?.photoURL}
        alt=""
        sx={{ cursor: "pointer", width: "30px", height: "30px" }}
        onClick={handleClick}
      >
        {user?.providerData?.[0]?.displayName?.[0]}
      </Avatar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ mt: 0.5 }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default AppBar;
