import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { httpsCallable } from "firebase/functions";
import { functions } from "src/firebase";
import { authState } from "src/store/auth";
import ConfirmPopup from "src/shared/components/confirmation-popup";

const TableActions = ({
  rowId,
  setSearchBy,
}: {
  rowId: string;
  setSearchBy: Function;
}) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  //Callable functions
  const archiveEmployee = httpsCallable(functions, "archiveEmployee");

  const handleArchive = () => {
    handleClose();
    setIsLoading(true);
    archiveEmployee({ employeeId: rowId })
      .then(() => {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "The employee has been archived successfully!",
          },
        ]);
        setIsLoading(false);
        setSearchBy("");
        navigate("/employees/active");
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleNavigateToEditPage = () => {
    navigate(`/employees/${rowId}/edit`);
  };

  return (
    <Stack direction={"row"}>
      {permissions?.arsannEmployees.edit && (
        <>
          <LoadingButton
            variant="contained"
            color="tertiary"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}
            size="small"
            sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
          >
            Actions
          </LoadingButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleNavigateToEditPage}
              sx={{
                width: "90px",
                fontSize: "14px",
                color: "primary.main",
                p: "4px 15px",
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={handleOpenPopup}
              sx={{ fontSize: "14px", p: "4px 15px" }}
            >
              Archive
            </MenuItem>
          </Menu>
        </>
      )}
      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="This action will remove this employee from active list and add him to
        archive list"
        submitBtnLabel="Archive"
        onSave={handleArchive}
        isLoading={isLoading}
      />
    </Stack>
  );
};
export default TableActions;
