import { Box, Stack, Typography } from "@mui/material";
import PaymentMethodChart from "./chart";
import { IPaymentMethodReports } from "src/types/business-intelligence";

const PaymentMethod = ({
  data,
}: {
  data: IPaymentMethodReports | undefined;
}) => {
  return (
    <Stack
      borderRadius={"8px"}
      boxShadow={" 0px 2.232px 11.161px 0px rgba(0, 0, 0, 0.10)"}
      pt={{ xs: 1, lg: 3 }}
      pb={{ xs: 0, lg: 1 }}
      bgcolor={"#242424"}
      height={"100%"}
    >
      <Box px={4} py={"11px"}>
        <Typography
          fontSize={{ xs: "16px", lg: "20px" }}
          fontWeight={500}
          color={"#FFFFFF"}
          lineHeight={"23.5px"}
        >
          Total Payment Methods Analytics
        </Typography>
      </Box>

      <Box
        width={"100%"}
        bgcolor={"#000000"}
        height={"2.3px"}
        my={{ lg: 3, xs: 2 }}
      />
      <PaymentMethodChart data={data} />
    </Stack>
  );
};

export default PaymentMethod;
