import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";

const LocationsInfo = () => {
  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        spacing={"11px"}
        display={"flex"}
        alignItems={"center"}
      >
        <Avatar
          sx={{
            bgcolor: "linear-gradient(180deg, #656464 0%, #373737 100%)",
            width: "80px",
            height: "80px",
            color: "#FFFFFF",
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: "22px",
          }}
        >
          AM
        </Avatar>
        <Box>
          <Typography
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#FFFFFF"}
            mb={"5px"}
          >
            Jaddah hospital{" "}
          </Typography>
          <Typography
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#ACA8A8"}
          >
            Renting Agreement type{" "}
          </Typography>
        </Box>
      </Stack>

      {/* dividr */}
      <Box bgcolor={"#454343"} width={"100%"} height={"1px"} />
      <Stack spacing={"5px"}>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          Location Name in English
        </Typography>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          مستشفي جدة
        </Typography>
      </Stack>
      <Stack spacing={"5px"}>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          Location Name in Arabic
        </Typography>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          مستشفي جدة
        </Typography>
      </Stack>
      <Stack spacing={"5px"}>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          Address
        </Typography>
        <Typography
          fontSize={"16px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          New street, Al-Ryadih
        </Typography>
      </Stack>

      <Box height={"551px"} bgcolor={"#9F9F9F"} />
      <Box mt={"auto"} display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          sx={{ padding: "12px 16px", borderRadius: "6px" }}
        >
          Edit main info
        </Button>
      </Box>
    </Stack>
  );
};

export default LocationsInfo;
