import { Box, Typography } from "@mui/material";
import NoFiltersIcon from "src/assets/svg-icons/no-filters.svg";

const NoFilterSelected = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"2px"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"60vh"}
    >
      <img src={NoFiltersIcon} alt="" />
      <Typography fontSize={"20px"} align="center" sx={{ color: "#938A80" }}>
        Please, Select Required Location and Time Range
      </Typography>
    </Box>
  );
};

export default NoFilterSelected;
