import { Box, Grid, Stack, Typography } from "@mui/material";
import Actions from "./actions";
import ServicesInfo from "./services-info";
import BoxInfo from "./info";

const Services = () => {
  return (
    <Stack spacing={"20px"}>
      <Box
        borderRadius={"12px"}
        bgcolor={"#1A1A1A"}
        boxShadow={"0px 14px 44px 0px rgba(0, 0, 0, 0.35)"}
        p={3}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            fontSize={"18px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#FFFFFF"}
          >
            Valet Service
          </Typography>
          <Actions rowId="" />
        </Box>
        {/* divider */}
        <Box
          bgcolor={"#454343"}
          width={"100%"}
          height={"1px"}
          mt={"15px"}
          mb={"20px"}
        />
        <ServicesInfo
          serviceType="Regular"
          parkingSpaces="40"
          payment="Flexible"
          pricing="Custom price"
        />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container mt={"12px"} spacing={"13px"}>
            <Grid item xs={12} sm={2.4}>
              <BoxInfo title="Pricing Model" value="Custom by hours" />
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <BoxInfo title="Starting hours" value="4" />
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <BoxInfo title="Price" value="SAR 40" />
            </Grid>
            <Grid item xs={12} sm={2.6}>
              <BoxInfo title="Price for exceeding hours" value="SAR 100" />
            </Grid>
            <Grid item xs={12} sm={2.2}>
              <Typography
                fontSize={"10px"}
                fontWeight={600}
                lineHeight={"16px"}
                color={"#FFFFFF"}
                mb={"5px"}
              >
                Exceeding Price Type
              </Typography>
              <Typography
                fontSize={"10px"}
                fontWeight={600}
                lineHeight={"16px"}
                color={"#FFFFFF"}
                mb={"5px"}
              >
                <Typography
                  fontSize={"14px"}
                  fontWeight={600}
                  lineHeight={"16px"}
                  color={"#ACA8A8"}
                  mb={"5px"}
                >
                  By Hour
                </Typography>{" "}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        borderRadius={"12px"}
        bgcolor={"#1A1A1A"}
        boxShadow={"0px 14px 44px 0px rgba(0, 0, 0, 0.35)"}
        p={3}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            fontSize={"18px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#FFFFFF"}
          >
            Self Parking
          </Typography>
          <Actions rowId="" />
        </Box>
        {/* divider */}
        <Box
          bgcolor={"#454343"}
          width={"100%"}
          height={"1px"}
          mt={"15px"}
          mb={"20px"}
        />
        <ServicesInfo
          serviceType="Regular"
          parkingSpaces="40"
          payment="At Exit"
          pricing="Hourly price"
        />

        <Box sx={{ flexGrow: 1 }}>
          <Grid container mt={"12px"} spacing={"13px"}>
            <Grid item xs={12} sm={3} mb={{ xs: "0", sm: "2px" }}>
              <BoxInfo title="Grace period" value="3 Hours" />
            </Grid>
            <Grid item lg={9} display={{ xs: "none", sm: "block" }} />

            <Grid item xs={12} sm={3}>
              <BoxInfo title="Starting hours" value="15 Min" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <BoxInfo title="Price" value="Sar 30" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <BoxInfo title="Price for exceeding hours" value="Sar 15" />
            </Grid>
            <Grid item xs={12} sm={3} mb={"2px"}>
              <Typography
                fontSize={"10px"}
                fontWeight={600}
                lineHeight={"16px"}
                color={"#FFFFFF"}
                mb={"5px"}
              >
                Exceeding Price Type
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                lineHeight={"16px"}
                color={"#ACA8A8"}
              >
                By Hour
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <BoxInfo title="ticket violation price" value="Sar 15" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
};

export default Services;
