import { Box, Stack, Typography } from "@mui/material";

interface IProps {
  label: string;
  value: string;
  icon: string
}

const StatisticsBox = ({ label, value, icon }: IProps) => {
  return (
    <Stack
      direction={"row"}
      padding={"30px 37px"}
      bgcolor={"#242424"}
      borderRadius={"12px"}
      justifyContent={"space-between"}
    >
      <Box>
        <Typography color={"#B0B0B0"} fontSize={"18px"} fontWeight={500} mb={3.35} lineHeight={1}>
          {label}
        </Typography>
        <Typography  fontSize={"18px"} fontWeight={600} lineHeight={1}>{value}</Typography>
      </Box>
      <img src={icon} alt="" />
    </Stack>
  );
};

export default StatisticsBox;
