import { Box, Typography } from "@mui/material";

interface IProps {
  title: string;
  value: string;
}
const BoxInfo = (props: IProps) => {
  const { title, value } = props;
  return (
    <Box
      borderRadius={"6px"}
      border={"1px solid #5B5B5B"}
      padding={"10px 15px"}
    >
      <Typography
        fontSize={"9px"}
        fontWeight={600}
        lineHeight={"9px"}
        color={"#A1A5B7"}
        mb={"5px"}
      >
        {title}
      </Typography>
      <Typography
        fontSize={"12px"}
        fontWeight={600}
        lineHeight={"12px"}
        color={"#FFFFFF"}
      >
        {value}
      </Typography>
    </Box>
  );
};
export default BoxInfo;
