import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "src/assets/svg-icons/close.svg";
import CarIcon from "src/assets/svg-icons/car.svg";
import PersonIcon from "src/assets/svg-icons/person.svg";
import InfoItem from "../../info-item";
import moment from "moment";

interface IProps {
  open: boolean;
  onClose: any;
  transDetails: any;
}

const TransactionsDetailsDrawer = ({ open, onClose, transDetails }: IProps) => {
  console.log({ transDetails });
  const checkInEmployee = transDetails?.involvedEmployee.find(
    (item: any) => item.operationType === "checkin"
  );
  const checkOutEmployee = transDetails?.involvedEmployee.find(
    (item: any) => item.operationType === "checkout"
  );
  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box minWidth={{ xs: "100vw", md: "500px" }} padding={"40px 30px"}>
        {/* header */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #454343"}
          paddingBottom={"29px"}
        >
          <Typography>Transaction Details</Typography>
          <IconButton sx={{ p: "0" }} onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </IconButton>
        </Stack>
        {/* content */}
        <Box paddingY={"26px"}>
          <Stack gap={2.5}>
            {/* ticket details */}
            <InfoItem label="#Ticket-Num" value={transDetails.ticketNumber} />
            <InfoItem label="Location" value={transDetails.locationName} />
            <InfoItem
              label="Service"
              value={transDetails.isValet ? "Valet" : "Self Parking"}
            />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Parking fees"
                  value={transDetails.locationPrice || "--"}
                />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Payment Method"
                  value={transDetails?.paymentInfo?.paymentMode || "--"}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Check in Date"
                  value={
                    moment(transDetails.checkInTime).format("D/M/YYYY") || "--"
                  }
                />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Check out Date"
                  value={
                    moment(transDetails.checkOutTime).format("D/M/YYYY") || "--"
                  }
                />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between" || "--"}>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Check in Time"
                  value={
                    moment(transDetails.checkInTime).format("h:mm A") || "--"
                  }
                />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Check out Time"
                  value={
                    moment(transDetails.checkOutTime).format("h:mm A") || "--"
                  }
                />
              </Box>
            </Stack>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* car details */}
            <Stack direction={"row"} gap={1}>
              <img src={CarIcon} alt="" />
              <Typography>Car Details</Typography>
            </Stack>
            <InfoItem
              label="#Plate-Num"
              value={
                transDetails.plateNumber && transDetails.plateLetter
                  ? `${transDetails.plateNumber}-${transDetails.plateLetter}`
                  : "--"
              }
            />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <InfoItem
                label="Vehicle Description"
                value={transDetails.vehicleDescription || "--"}
              />
            </Stack>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* customer details */}
            <Stack direction={"row"} gap={1}>
              <img src={PersonIcon} alt="" />
              <Typography>Customer Details</Typography>
            </Stack>
            <InfoItem label="Name" value={transDetails.customerName || "--"} />
            <InfoItem
              label="Mobile Number"
              value={transDetails.customerNumber || "--"}
            />
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            <InfoItem
              label="Check in Employee"
              value={checkInEmployee?.name || "--"}
            />
            <InfoItem
              label="Check out Employee"
              value={checkOutEmployee?.name || "--"}
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default TransactionsDetailsDrawer;
