import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ActiveLocationsTable from "./table";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { db } from "src/firebase";

const ArchivedLocationsPage = () => {
  const [locations, setLocations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    let unsubscribe: () => void;

    unsubscribe = onSnapshot(
      query(collection(db, "locations"), where("isActive", "==", false)),
      (QuerySnapshot) => {
        const fetchedEmployees: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedEmployees.push({ ...doc.data(), id: doc.id });
        });

        setLocations(fetchedEmployees);
        setIsLoading(false);
      }
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Archived locations
      </Typography>
      <ActiveLocationsTable rowsList={locations} isLoading={isLoading} />
    </Box>
  );
};

export default ArchivedLocationsPage;
