import { LoadingButton } from "@mui/lab";
import { Typography, Divider, Link, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import BlackHorseImg from "src/assets/black_horse.png";
import LogoIcon from "src/assets/svg-icons/arsann-logo.svg";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { authentication } from "src/firebase";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

interface FormInputs {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
});

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsLoading(true);
    sendPasswordResetEmail(authentication, data.email)
      .then((response) => {
        console.log({ response });
        reset({ email: "" });
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "success",
            message:
              "An email with password reset link has been sent. Please check your inbox.",
          },
        ]);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message: errorMessage || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  return (
    <Grid container>
      <Grid
        xs={12}
        md={6}
        px={{ xs: "16px", lg: "180px" }}
        pt={{ xs: "90px", md: "120px" }}
        textAlign={"center"}
      >
        <img src={LogoIcon} alt="logo" height={"95px"} />
        <Typography
          fontSize={"24px"}
          fontWeight={600}
          lineHeight={"24px"}
          my={"32px"}
        >
          Forgot password
        </Typography>
        <Divider sx={{ mb: "35px" }}>
          <Typography fontSize={"12px"} color={"#A1A5B7"}>
            Fill your email
          </Typography>
        </Divider>
        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={3} alignItems={"flex-start"}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Email"
                  {...field}
                  error={!!formState.errors.email}
                  helperText={formState.errors.email?.message}
                  fullWidth
                  size="small"
                />
              )}
            />
            <Stack ml={"auto"} direction={"row"} py={1} gap={1}>
              Go back to
              <Link
                color={"primary"}
                component={RouterLink}
                underline="always"
                to="/login"
              >
                Login
              </Link>
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Send Reset Link
            </LoadingButton>
          </Stack>
        </form>
      </Grid>
      <Grid
        md={6}
        display={{ xs: "none", md: "flex" }}
        flexDirection={"column"}
        alignItems={"center"}
        borderRadius={2}
        minHeight={"100vh"}
        maxHeight={"100vh"}
      >
        <img height={"100%"} src={BlackHorseImg} alt="" width={"100%"} />
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
