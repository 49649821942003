import React from "react";
import { useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./style.css";

const DateRangeInput = (props: any) => {
  const {
    startDate,
    startDateId,
    endDate,
    endDateId,
    onDatesChange,
    isOutsideRange = () => {},
    onFocusChange = () => {},
    showClearDates = false,
    disabled = false,
    minimumNights = 0,
    onClose,
  } = props;
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
    onFocusChange(focusedInput);
  };

  return (
    <DateRangePicker
      small
      startDate={startDate} // moment.Moment | null
      startDateId={startDateId} // moment.Moment | null
      endDate={endDate} // momentPropTypes.momentObj or null
      endDateId={endDateId} // string
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
      isOutsideRange={isOutsideRange}
      showDefaultInputIcon
      showClearDates={showClearDates}
      disabled={disabled}
      startDatePlaceholderText={"From"}
      endDatePlaceholderText={"To"}
      minimumNights={minimumNights}
      onClose={onClose}
    />
  );
};

export default DateRangeInput;
