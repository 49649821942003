import { Box, Grid, Typography } from "@mui/material";
import LocationsInfo from "./location-info";
import LocationProfileTabs from "./tabs";

const LocationsProfilePage = () => {
  return (
    <Box p={"30px 0 30px 20px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Location profile
      </Typography>

      <Grid container>
        <Grid
          item
          xs={12}
          lg={3.4}
          bgcolor={"#232323"}
          borderRadius={"10px"}
          p={2}
          mb={{ xs: 3, lg: 0 }}
        >
          <LocationsInfo />
        </Grid>
        <Grid item xs={0} lg={0.2} />
        <Grid item xs={12} lg={8.2}>
          <LocationProfileTabs />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationsProfilePage;
