import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import DeleteIcon from "src/assets/svg-icons/delete.svg";
import AddIcon from "src/assets/svg-icons/add.svg";
import { FormInputs } from "../constants";

const GatesInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const { fields, append, remove, replace } = useFieldArray({
    name: "gates",
    control,
  });

  const addGate = () => {
    append({
      name: "",
    });
  };

  const deleteGate = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    remove(index);
  };

  return (
    <Box
      p={3}
      borderRadius={"10px"}
      border={"1.036px solid #5B5B5B"}
      mb={"53px"}
    >
      <Typography fontSize={"23px"} mb={2}>
        Gates Info
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {fields.map((field, index) => (
          <Grid item sm={4} key={field.id}>
            <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
              <Controller
                name={`gates.${index}.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    // fullWidth
                    label="Gate name"
                    size="small"
                    {...field}
                    error={errors.gates && !!errors.gates[index]?.name}
                    helperText={
                      errors.gates && errors.gates[index]?.name?.message
                    }
                  />
                )}
              />

              <IconButton onClick={() => deleteGate(index)}>
                <img src={DeleteIcon} alt="" />
              </IconButton>

              {fields.length - 1 === index && (
                <IconButton onClick={addGate}>
                  <img src={AddIcon} alt="" />
                </IconButton>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GatesInfo;
