import { Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ISalesByLocation } from "src/types/reports";

const colors = [
  "#A155B9",
  "#CC9C33",
  "#CC3723",
  "#0075FF",
  "#16AA75",
  "#EB1DB1",
  "#3528C1",
];

const LocationsSalesChart = ({ data }: { data: ISalesByLocation[] }) => {
  const locationsNames : string[]  = [];
  const locationsSales : number[] = [];

  let total = 0;

  data?.forEach((item) => {
    locationsNames.push(item.locationName || "");
    locationsSales.push(item.locationSales || 0);
    total += item.locationSales;
  });

  const options: ApexCharts.ApexOptions = {
    chart: {
      redrawOnParentResize: true,
      width: 80,
      type: "pie",
      foreColor: "#FFF",
    },
    noData: {
      text: "No data available ",
      style: {
        fontSize: "15px",
      },
    },
    colors: colors,
    labels: locationsNames,
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function (val, opts) {
        return (
          val +
          " - " +
          ((opts.w.globals.series[opts.seriesIndex] / total) * 100).toFixed(1) +
          "%"
        );
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       legend: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    // ],
  };

  return (
    <Box
      borderRadius={"12px"}
      overflow={"hidden"}
      pb={"10px"}
      bgcolor={"#242424"}
    >
      <Box padding={"21px 24px"} bgcolor={"#242424"}>
        <Typography fontSize={"20px"} fontWeight={700}>
          Sales by Location
        </Typography>
      </Box>
      <Box height={"2px"} bgcolor={"#000000"} />
      <Box pr={{ xs: "0", md: "150px" }} pb={1} pt={2}>
        <ReactApexChart
          options={options}
          series={locationsSales}
          type="pie"
          height={280}
          width={"100%"}
        />
      </Box>
    </Box>
  );
};

export default LocationsSalesChart;
