import { Box, Typography } from "@mui/material";
import React from "react";

interface IPropsTypes {}

export const NoData: React.FC<IPropsTypes> = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={280}
      p={3}
    >
      <Typography fontWeight={600} variant="h6" textAlign={"center"}>
        No data
      </Typography>

      <Typography textAlign={"center"}>
        Double check for your search entry or reset your applied filters
      </Typography>
    </Box>
  );
};
