import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ISalesByDay } from "src/types/reports";

const colors = ["#0075FF", "#16AA75", "#A155B9", "#CC9C33"];

const PaymentsLinearChart = ({ data }: { data: ISalesByDay[] }) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const xAxisLabels: any = [];
  const totalArr: any = [];
  const cardArr: any = [];
  const onlineArr: any = [];
  const cashArr: any = [];

  data?.forEach((item) => {
    xAxisLabels.push(item.date);
    totalArr.push(item.totalSales);
    cardArr.push(item.totalCardSales);
    onlineArr.push(item.totalOnlineSales);
    cashArr.push(item.totalCashSales);
  });

  const series = [
    {
      name: "Total Sales",
      data: totalArr,
    },
    {
      name: "Card Payment",
      data: cardArr,
    },
    {
      name: "Cash Payment",
      data: cashArr,
    },
    {
      name: "Online Payment",
      data: onlineArr,
    },
  ];

  const options: ApexCharts.ApexOptions = {
    theme: {
      mode: "dark",
    },
    noData: {
      text: "No data available ",
      style: {
        fontSize: "15px",
      },
    },
    colors: colors,
    chart: {
      redrawOnParentResize: true,
      type: "line",
      zoom: {
        enabled: false,
      },
      foreColor: "#f0f0f0",
      background: "#242424",
      toolbar: {
        show: false, // Hide the download icons
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Adjust the size of the marker
      shape: "circle",
      strokeColors: colors,
    },
    stroke: {
      width: 2.5,
      curve: "straight",
    },
    grid: {
      show: true,
      borderColor: "#000",
    },
    xaxis: {
      categories: xAxisLabels,
      labels: {
        show: false,
      },
    },
    legend: {
      show: true,
      fontSize: "13.3px",
      markers: {
        width: 23.5,
        height: 9,
        strokeWidth: 0,
        radius: 0,
      },
      itemMargin: {
        horizontal: matchesMediumUp ? 15 : 5,
        vertical: matchesMediumUp ? 5 : 5,
      },
    },
  };

  return (
    <Box
      borderRadius={"12px"}
      overflow={"hidden"}
      pb={"10px"}
      bgcolor={"#242424"}
    >
      <Box padding={"21px 24px"} bgcolor={"#242424"}>
        <Typography fontSize={"20px"} fontWeight={700}>
          Total Sales
        </Typography>
      </Box>
      <Box height={"2px"} bgcolor={"#000000"} />
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={410}
        width={"100%"}
      />
    </Box>
  );
};

export default PaymentsLinearChart;
