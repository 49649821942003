import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
  TextField,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db, functions } from "src/firebase";
import moment from "moment";
import StatusChip from "src/shared/components/status-chip";
import DateRangeInput from "src/shared/components/date-range";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { httpsCallable } from "firebase/functions";
import NoFilterSelected from "../no-filters-selected";
import TransactionsDetailsDrawer from "../transaction-details-popup";
import { downloadFile } from "src/utils";

const paymentsTypes = ["Card", "Cash", "Online", "Free"];

const tableHeadText = [
  "Ticket Num",
  "Location Name",
  "Service",
  "Service type",
  "Check-out",
  "Payment Method",
  "Date",
  "Status",
];

const TransactionsTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [endDateFocused, setEndDateFocused] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState<string>("");
  const [locations, setLocations] = useState<any[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [selectedService, setSelectedService] = useState<string>("all");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [transactionsList, setTransactionsList] = useState<any[]>([]);
  const [lastVisibleRow, setLastVisibleRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState();

  //Callable functions
  const fetchTransactionsList = httpsCallable(functions, "listTransactions");
  const exportTransactionsList = httpsCallable(functions, "exportTransactions");

  // useEffect(() => {
  //   // setIsLoading(true);
  //   fetchTransactionsList({
  //     limit: rowsPerPage,
  //     offset: page,
  //     ...(!!searchBy && {
  //       search: searchBy,
  //     }),
  //     from: dateRange?.startDate || undefined,
  //     to: dateRange?.endDate || undefined,
  //     ...(selectedLocations.length > 0 && {
  //       locations: selectedLocations.map((loc) => loc.name),
  //     }),
  //     ...(selectedPayments.length > 0 && {
  //       paymentTypes: selectedPayments,
  //     }),
  //     ...(selectedService !== "all" && {
  //       service: selectedService,
  //     }),
  //   })
  //     .then((result: any) => {
  //       console.log({ result });
  //       setData(result.data.data);
  //       setTotalCount(result.data.total);
  //       // setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       const { message, details } = error;
  //       // setIsLoading(false);
  //     });
  // }, [
  //   selectedLocations,
  //   dateRange?.startDate,
  //   dateRange?.endDate,
  //   page,
  //   rowsPerPage,
  //   selectedService,
  //   selectedPayments,
  //   searchBy,
  // ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (searchedVal: string) => {
    setPage(0);
    setSearchBy(searchedVal);
  };

  const clearSearch = () => {
    if (searchBy !== "") {
      handleSearch("");
    }
  };

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  //Filters
  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };
  const handleChangeLocation = (e: SelectChangeEvent) => {
    setSelectedLocation(e.target.value);
  };
  const handleChangePayments = (
    event: SelectChangeEvent<typeof paymentsTypes>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedPayments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleExport = () => {
    setIsLoadingExport(true);
    exportTransactionsList({
      ...(!!searchBy && {
        search: searchBy,
      }),
      from: dateRange?.startDate || undefined,
      to: dateRange?.endDate || undefined,
      ...(!!selectedLocation && {
        locations: [selectedLocation],
      }),
      ...(selectedPayments.length > 0 && {
        paymentTypes: selectedPayments,
      }),
      ...(selectedService !== "all" && {
        service: selectedService,
      }),
    })
      .then((result: any) => {
        console.log({ result });
        setIsLoadingExport(false);
        const utf8CSVData = `\uFEFF${result?.data.data}`;
        downloadFile(utf8CSVData, "text/csv", "transactions");
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "Downloaded successfully",
          },
        ]);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoadingExport(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setClickedRow(undefined);
  };

  // const handleClickRow = () => {
  //   setOpen(false);
  // };

  // Firebase SDK
  const fetchData = async (pageSize: number) => {
    const startDate = moment(dateRange.startDate);
    const endDate = moment(dateRange.endDate);

    if (endDate.diff(startDate, "days") > 31) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "The maximum date range allowed is 31 days!",
        },
      ]);
      return;
    }
    setIsLoading(true);
    try {
      const queryRef = collection(db, "transaction_v2");
      const constraints = [];
      if (!!selectedLocation) {
        constraints.push(where("locationName", "==", selectedLocation));
      }
      if (!!dateRange?.startDate) {
        // const from = moment(dateRange.startDate, "MM/DD/YYYY").valueOf();
        const from = new Date(dateRange?.startDate).getTime();
        console.log({ from });
        constraints.push(where("checkInTime", ">=", from));
      }
      if (!!dateRange?.endDate) {
        // const to = moment(dateRange.endDate, "MM/DD/YYYY").valueOf();
        const to = new Date(dateRange?.endDate).getTime();
        console.log({ to });
        constraints.push(where("checkInTime", "<=", to));
      }
      if (!!searchBy) {
        constraints.push(where("ticketNumber", "==", searchBy));
      }
      if (selectedService === "valet") {
        constraints.push(where("isValet", "==", true));
      }
      if (selectedService === "selfParking") {
        constraints.push(where("isSelfParking", "==", true));
      }
      if (selectedPayments.length > 0) {
        constraints.push(
          where(
            "paymentInfo.paymentMode",
            "in",
            selectedPayments.map((pay: string) => pay)
          )
        );
      }

      console.log("before", { constraints });
      const fetchAllQuery = query(queryRef, ...constraints);
      const allItems = await getDocs(fetchAllQuery);

      console.log({ allItems }, allItems.size);
      setTotalCount(allItems.size);

      if (page > 0 && !!lastVisibleRow) {
        constraints.push(startAfter(lastVisibleRow));
      }

      constraints.push(limit(pageSize));

      console.log({ constraints });

      const paginatedQuery = query(queryRef, ...constraints);
      const paginatedItems = await getDocs(paginatedQuery);
      console.log({ paginatedItems }, paginatedItems.size);

      const transactions = paginatedItems.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setIsLoading(false);
      console.log({ transactions });
      setTransactionsList(transactions);

      // Set the last visible document
      const lastDoc = paginatedItems.docs[paginatedItems.docs.length - 1];
      setLastVisibleRow(lastDoc);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      // setTransactionsList([]);
      // setTotalCount(0);
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Error Fetching Data",
        },
      ]);
    }
  };

  const emptyDataArr = transactionsList?.length === 0 && !isLoading;

  useEffect(() => {
    console.log("rerender");
    if (!!dateRange?.startDate && !!dateRange.endDate && locations.length > 0) {
      console.log("re");
      fetchData(rowsPerPage);
    }
  }, [
    page,
    rowsPerPage,
    selectedLocation,
    dateRange,
    selectedService,
    selectedPayments,
    searchBy,
  ]);

  useEffect(() => {
    setPage(0);
    setLastVisibleRow(undefined);
  }, [
    selectedLocation,
    dateRange,
    selectedService,
    selectedPayments,
    searchBy,
  ]);

  // Fetch locations
  useEffect(() => {
    let unsubscribeLocations: () => void;

    unsubscribeLocations = onSnapshot(
      query(collection(db, "locations")),
      (QuerySnapshot) => {
        const fetchedLocations: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedLocations.push({ ...doc.data(), id: doc.id });
        });
        // Sort locations alphabetically by name
        fetchedLocations.sort((a, b) => a.name.localeCompare(b.name));
        setLocations(fetchedLocations);
      }
    );

    return () => {
      if (unsubscribeLocations) {
        unsubscribeLocations();
      }
    };
  }, []);

  const getTransactionStatus = (transactionData: any) => {
    let status = "Pending"; // Default status

    if (
      transactionData.paymentInfo &&
      transactionData.paymentInfo.paymentMode
    ) {
      const paymentMode = transactionData.paymentInfo.paymentMode;

      switch (paymentMode) {
        case "Free":
          status = "Free";
          break;
        case "Card":
        case "Online":
        case "Cash":
          status = "Paid";
          break;
        default:
          break;
      }
    }

    return status;
  };

  console.log({ page, transactionsList });

  return (
    <>
      <Grid container spacing={1} mb={3}>
        {/* locations */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Location</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedLocation}
              label="Location"
              onChange={handleChangeLocation}
            >
              {locations.map((location) => {
                return (
                  <MenuItem key={location.id} value={location.name}>
                    {location.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {/* date range */}
        <Grid item xs={12} md={6}>
          <Box width={{ xs: "100%", sm: "fit-content" }}>
            <DateRangeInput
              startDate={
                dateRange?.startDate ? moment(dateRange?.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange?.endDate ? moment(dateRange?.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
              isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <LoadingButton
            variant="contained"
            sx={{ gap: 0.5 }}
            onClick={handleApplyMainFilters}
            // loading={}
          >
            Apply
          </LoadingButton>
        </Grid> */}
      </Grid>
      {/* </Stack> */}
      {!dateRange?.startDate || !dateRange?.endDate || !selectedLocation ? (
        <NoFilterSelected />
      ) : (
        <Paper
          sx={{
            bgcolor: "#1A1A1A",
            boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
            padding: { xs: "15px", md: "30px" },
          }}
        >
          <Grid container spacing={{ xs: 1.4, sm: 1 }} mb={3}>
            {/* search */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search"
                value={searchBy}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ opacity: !searchBy ? 0 : 1 }}
                    >
                      <IconButton size="small" onClick={clearSearch}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* payment type */}
            <Grid item xs={12} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedPayments}
                  onChange={handleChangePayments}
                  input={<OutlinedInput label="Payment Type" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {paymentsTypes.map((payment) => (
                    <MenuItem key={payment} value={payment}>
                      <Checkbox
                        size="small"
                        checked={selectedPayments.indexOf(payment) > -1}
                      />
                      <ListItemText primary={payment} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Service */}
            <Grid item xs={12} md={2.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Service</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedService}
                  label="Service"
                  onChange={handleChangeServiceType}
                  fullWidth
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"valet"}>Valet</MenuItem>
                  <MenuItem value={"selfParking"}>Self Parking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!emptyDataArr ? (
              <Grid
                item
                xs={12}
                md={3.2}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <LoadingButton
                  variant="contained"
                  sx={{ gap: 0.5 }}
                  onClick={handleExport}
                  loading={isLoadingExport}
                >
                  Export
                </LoadingButton>
              </Grid>
            ) : null}
          </Grid>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeadText.map((item) => (
                    <TableCell
                      key={item}
                      sx={{
                        fontWeight: 600,
                        color: "#A1A5B7",
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableLoadingSkeleton
                  rowsLength={rowsPerPage}
                  cellsLength={9}
                />
              ) : (
                <TableBody>
                  {transactionsList?.map((row: any) => {
                    const rowStatus = getTransactionStatus(row);
                    return (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => {
                          setOpen(true);
                          setClickedRow(row);
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>{row.ticketNumber}</TableCell>
                        <TableCell>{row.locationName}</TableCell>
                        <TableCell>
                          {row.isValet ? "Valet" : "Self Parking"}
                        </TableCell>
                        <TableCell>{row.serviceUsed}</TableCell>
                        <TableCell>
                          <StatusChip
                            color={!!row.checkOutTime ? "success" : "warning"}
                            label={row.checkOutTime ? "Done" : "Not Done"}
                          />
                        </TableCell>
                        <TableCell>{row.paymentInfo?.paymentMode}</TableCell>
                        <TableCell>
                          {row?.checkInTime
                            ? moment(row?.checkInTime).format("DD/MM/yyyy")
                            : ""}
                        </TableCell>
                        <TableCell>
                          {!!rowStatus && (
                            <StatusChip
                              color={
                                rowStatus === "Paid"
                                  ? "success"
                                  : rowStatus === "Free"
                                  ? "blue"
                                  : rowStatus === "Refunded"
                                  ? "gray"
                                  : rowStatus === "Cancelled"
                                  ? "error"
                                  : "warning" //Pending
                              }
                              label={rowStatus}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>

            {emptyDataArr && <NoData />}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          {open && !!clickedRow ? (
            <TransactionsDetailsDrawer
              transDetails={clickedRow}
              open={open}
              onClose={handleCloseDrawer}
            />
          ) : null}
        </Paper>
      )}
    </>
  );
};
export default TransactionsTable;
