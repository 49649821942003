import { Box, Grid, Stack, Typography } from "@mui/material";
import PaymentsChart from "./chart";
import { IIncomeReports } from "src/types/business-intelligence";

const Payments = ({ data }: { data: IIncomeReports | undefined }) => {
  return (
    <Stack
      borderRadius={"8px"}
      boxShadow={" 0px 2.232px 11.161px 0px rgba(0, 0, 0, 0.10)"}
      pt={{ xs: 2, lg: 3 }}
      pb={{ xs: 1, lg: 1 }}
      bgcolor={"#242424"}
    >
      <Grid container px={4}>
        <Grid item xs={4}>
          <Typography
            fontSize={"12px"}
            fontWeight={600}
            color={"#CFCFCF"}
            mb={"3px"}
          >
            Paid
          </Typography>

          <Typography
            fontSize={{ xs: "14px", lg: "16px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            Sar {data?.total.completedAmount}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            fontSize={"12px"}
            fontWeight={600}
            color={"#CFCFCF"}
            mb={"3px"}
          >
            Free
          </Typography>
          <Typography
            fontSize={{ xs: "14px", lg: "16px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            Sar 0
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            fontSize={"12px"}
            fontWeight={600}
            color={"#CFCFCF"}
            mb={"3px"}
          >
            Cancelled
          </Typography>
          <Typography
            fontSize={{ xs: "14px", lg: "16px" }}
            fontWeight={500}
            color={"#FFF"}
          >
            Sar 0
          </Typography>
        </Grid>
      </Grid>

      <Box
        width={"100%"}
        bgcolor={"#000000"}
        height={"2.3px"}
        my={{ lg: 3, xs: 2 }}
      />
      <PaymentsChart data={data} />
    </Stack>
  );
};

export default Payments;
