import React, { FC, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  TextField,
  Stack,
  Box,
  Link,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link as RouterLink } from "react-router-dom";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { authState } from "src/store/auth";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { authentication } from "src/firebase";

interface FormInputs {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Form: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth, setAuth] = useRecoilState(authState);

  const { control, handleSubmit, formState } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsLoading(true);
    signInWithEmailAndPassword(authentication, data.email, data.password)
      .then( async (userCredential) => {
        const user: any = userCredential.user;
        console.log({ userCredential, user });
        const { claims } = await user.getIdTokenResult();        ;
        if (claims?.admin) {
          // Sign-in successful.
          setNotifications([
            ...notifications,
            {
              type: "success",
              message: "Signed in successfully.",
            },
          ]);
          setIsLoading(false);
          setAuth({...auth, user: user });
          window.location.reload();
        } else {
          signOut(authentication)
            .then(() => {
              // Sign-out successful.
              console.log("Signed out successfully");
              setIsLoading(false);
              setNotifications([
                ...notifications,
                {
                  type: "error",
                  message: "Not allowed to sign-in",
                },
              ]);
            })
            .catch((error) => {
              console.log(error);
              // An error happened.
            });
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message: errorMessage || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3} alignItems={"flex-start"}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label="Email"
              {...field}
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              fullWidth
              size="small"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <FormControl
              {...field}
              variant="outlined"
              error={!!formState.errors.password}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                aria-describedby="my-helper-text"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined fontSize="small" />
                      ) : (
                        <VisibilityOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText id="my-helper-text">
                {formState.errors.password?.message}
              </FormHelperText>
            </FormControl>
          )}
        />

        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <FormControlLabel
            control={<Checkbox sx={{ paddingY: "0" }} defaultChecked />}
            label="Remember me"
          />

          <Link
            color={"primary"}
            component={RouterLink}
            underline="always"
            to="/forgot-password"
          >
            Forgot Password?
          </Link>
        </Box>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          Sign in
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
