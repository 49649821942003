import { atom } from "recoil";
import { LocalStorageConstants } from "src/constants/_localStorage";
import { IUserPermissions } from "src/types/generic";
import {
  getLocalStoredData,
  setLocalStoredData,
} from "src/utils/_localStorage";

export const authState = atom<{
  user: any;
  permissions: IUserPermissions | undefined;
}>({
  key: "auth", // unique ID
  default: {
    user: getLocalStoredData(LocalStorageConstants.user),
    permissions: undefined,
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((data) => {
        if (data.user) {
          setLocalStoredData(LocalStorageConstants.user, data.user);
        }
      });
    },
  ],
});
