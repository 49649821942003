import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyB3zy7-uaS8jztoQMIh6WaW9xyHAX50_tY",
  authDomain: "arsann-prod.firebaseapp.com",
  databaseURL: "https://arsann-prod.firebaseio.com",
  projectId: "arsann-prod",
  storageBucket: "arsann-prod.appspot.com",
  messagingSenderId: "240867399828",
  appId: "1:240867399828:web:4d0f6c64ac595957a2bc81",
  measurementId: "G-7MXEE3QHQW"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);