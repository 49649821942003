import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";

const tableHeadText = ["#Ticket-Num", "Service", "Amount", "Date", "Time"];

function createData(
  ticket: string,
  service: string,
  amount: string,
  date: string,
  time: string
) {
  return { ticket, service, amount, date, time };
}

const rows = [
  createData("2748789278", "Valet", "23/4/2023", "23/4/2023 ", "12:00 PM "),
  createData("2748789278", "Valet", "23/4/2023", "23/4/2023 ", "12:00 PM "),
  createData("2748789278", "Valet", "23/4/2023", "23/4/2023 ", "12:00 PM "),
];
const Transactions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("year").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  return (
    <>
      <Stack direction={"row"} gap={2} mb={4} flexWrap={"wrap"}>
        {/* date range */}
        <Box sx={{ minWidth: 183 }}>
          <DateRangeInput
            startDate={
              dateRange?.startDate ? moment(dateRange?.startDate) : null
            } // moment.Moment | null
            startDateId={`your_unique_start_date_id`}
            endDate={dateRange?.endDate ? moment(dateRange?.endDate) : null} // moment.Moment | null
            endDateId={`your_unique_end_date_id`}
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setDateRange({
                startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                endDate: arg.endDate?.format("yyyy-MM-DD") as string,
              });
            }}
            showClearDates
          />
        </Box>
        <FormControl size="small" sx={{ minWidth: "180px" }}>
          <InputLabel id="demo-simple-select-label" sx={{ color: "#F5F5F5" }}>
            Service
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={selectedService}
            label="Service"
            // onChange={handleChangeServiceType}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"valet"}>Valet</MenuItem>
            <MenuItem value={"selfParking"}>Self Parking</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <TableContainer
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#A1A5B7",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={4} cellsLength={2} />
          ) : (
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.ticket}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: "#CC9C33",
                      textDecorationColor: "red",
                      textDecoration: "underline",
                    }}
                  >
                    {row.ticket}
                  </TableCell>
                  <TableCell>{row.service}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {false && <NoData />}
      </TableContainer>
    </>
  );
};
export default Transactions;
