import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import GatesInfo from "./gates-info";
import { FormInputs, schema } from "./constants";
import { useEffect, useState } from "react";
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { db, functions } from "src/firebase";
import LocationsMapLocation from "./google-map-location";
import AddEditServicePopup from "./add-edit-service-popup";
import ServicesTable from "./services-table";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { httpsCallable } from "firebase/functions";
import { useNavigate, useParams } from "react-router-dom";
import { FormModeTypes } from "src/types/generic";
import { LoadingButton } from "@mui/lab";

const AddEditLocationPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mode: FormModeTypes = !id ? "add" : "edit";
  const [clients, setClients] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [clickedCoordinates, setClickedCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 23.8859, lng: 45.0792 });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFetching, setIsLoadingFetching] = useState(
    true && mode === "edit"
  );

  //Callable functions
  const addLocation = httpsCallable(functions, "createLocation");
  const updateLocation = httpsCallable(functions, "updateLocation");

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (services.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Add services please",
        },
      ]);
      return;
    }
    if (!clickedCoordinates?.lat) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Select location on map please",
        },
      ]);
      return;
    }
    console.log({ data });
    const dataToSend = {
      ...data,
      services: services,
      latitude: clickedCoordinates?.lat,
      longitude: clickedCoordinates?.lng,
      // city: "test",
    };
    console.log({ dataToSend });
    setIsLoading(true);
    if (mode === "add") {
      addLocation(dataToSend)
        .then(() => {
          setNotifications([
            ...notifications,
            {
              type: "success",
              message: "The location has been added successfully!",
            },
          ]);
          setIsLoading(false);
          navigate("/locations/active");
        })
        .catch((error) => {
          const { message, details } = error;
          setIsLoading(false);
          setNotifications([
            ...notifications,
            {
              type: "error",
              message:
                details?.message || message || "Oops! Something went wrong.",
            },
          ]);
        });
    } else {
      updateLocation({ ...dataToSend, locationId: id })
        .then(() => {
          setNotifications([
            ...notifications,
            {
              type: "success",
              message: "The location has been updated successfully!",
            },
          ]);
          setIsLoading(false);
        })
        .catch((error) => {
          const { message, details } = error;
          setIsLoading(false);
          setNotifications([
            ...notifications,
            {
              type: "error",
              message:
                details?.message || message || "Oops! Something went wrong.",
            },
          ]);
        });
    }
  };

  const methods = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      gates: [
        {
          name: "",
        },
      ],
    },
  });
  const { handleSubmit, formState, reset, control } = methods;

  const onClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    let unsubscribeClients: () => void;

    unsubscribeClients = onSnapshot(
      query(collection(db, "clients")),
      (QuerySnapshot) => {
        const fetchedClients: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedClients.push({ ...doc.data(), id: doc.id });
        });

        setClients(fetchedClients);
      }
    );

    return () => {
      if (unsubscribeClients) {
        unsubscribeClients();
      }
    };
  }, []);

  //Fetch Locations data
  useEffect(() => {
    if (id) {
      let unsubscribe: () => void;
      const docRef = doc(db, "locations", `${id}`);

      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = { ...doc.data() };
          console.log({ data });
          // setLocationData(data);
          setIsLoadingFetching(false);
          // Reset the form and permissions with employee data
          reset({
            clientId: data.clientId,
            nameAr: data.nameAr,
            nameEn: data.nameEn,
            address: data.address,
            phoneNumber: data.phoneNumber,
            gates: data.gates,
          });
          setServices(data.services);
          setClickedCoordinates({ lat: data.latitude, lng: data.longitude });
        } else {
          console.log("No such document!");
          setNotifications([
            ...notifications,
            {
              type: "error",
              message: "Error fetching location data!",
            },
          ]);
        }
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [id]);

  console.log({ clients, services, clickedCoordinates });

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        {mode === "add" ? "Add Location" : "Update Location"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                size="small"
                error={!!formState.errors.clientId}
              >
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Client"
                    >
                      {clients.map((client) => {
                        return (
                          <MenuItem key={client.id} value={client.id}>
                            {client.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.clientId?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="nameEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Location Name in English"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.nameEn}
                    helperText={formState.errors.nameEn?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Location Name in Arabic"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.nameAr}
                    helperText={formState.errors.nameAr?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Complete Address"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.address}
                    helperText={formState.errors.address?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Contact Number"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.phoneNumber}
                    helperText={formState.errors.phoneNumber?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* google map location */}
          <Box mb={2}>
            <LocationsMapLocation
              setClickedCoordinates={setClickedCoordinates}
              clickedCoordinates={clickedCoordinates}
            />
          </Box>
          {/* gates */}
          <GatesInfo />
          {/* services */}
          <Box
            p={3}
            borderRadius={"10px"}
            border={"1.036px solid #5B5B5B"}
            mb={"33px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography fontSize={"26px"} fontWeight={600}>
                Services
              </Typography>
              <Button variant="contained" onClick={handleOpen}>
                Add a New Service
              </Button>
            </Stack>
            {services.length === 0 ? (
              <Box py={"80px"}>
                <Typography
                  fontSize={"20px"}
                  color={"#A3A3A3"}
                  fontWeight={600}
                  align="center"
                >
                  No Services Added Yet
                </Typography>
              </Box>
            ) : (
              <Box mt={"40px"}>
                <ServicesTable services={services} setServices={setServices} />
              </Box>
            )}
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Stack
              mt={3}
              spacing={2}
              direction={"row"}
              sx={{ width: { xs: "100%", lg: "426px" } }}
            >
              <Button variant="outlined" fullWidth>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={isLoading}
              >
                {mode === "add" ? "Add Location" : "Update Location"}
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </form>
      <AddEditServicePopup
        open={open}
        onClose={onClose}
        setServices={setServices}
      />
    </Box>
  );
};

export default AddEditLocationPage;
