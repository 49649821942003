import { Box, Typography } from "@mui/material";

const NoAccess = () => {
  return (
    <Box pt={40}>
      <Typography align="center">You have no access</Typography>
    </Box>
  );
};

export default NoAccess;
