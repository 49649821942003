import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import { Box, Button } from "@mui/material";
import assignIcon from "src/assets/svg-icons/assign.svg";

const rows = ["Gate 1", "Gate 2", "Gate 3"];

const Gates = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Box
      sx={{
        bgcolor: "#1A1A1A",
        boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
        padding: "30px",
      }}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Gate Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={4} cellsLength={2} />
          ) : (
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row}
                  </TableCell>
                  <TableCell align="right">
                    <TableActions rowId={"row.id"} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {false && <NoData />}
      </TableContainer>

      <Box display={"flex"} justifyContent={"flex-end"} pt={"30px"}>
        <Button startIcon={<img src={assignIcon} alt="" />} variant="contained">
          Add new gate
        </Button>{" "}
      </Box>
    </Box>
  );
};
export default Gates;
